<template>
    <div>
        <vue-header title="添加用户" isReturn="true" />
        <div v-if="doctor">
            <ul class="profile-tion" >
                <li class="profile-li flex flex-y">
                    <div class="profile-name">
                        <span>*</span>手机号
                    </div>
                    <div class="flex-1">
                        <input class="profile-input" placeholder="请输入您的手机号" v-model="iphone" />
                    </div>
                </li>
                <li class="profile-li flex flex-y">
                    <div class="profile-name">
                        <span>*</span>身份证
                    </div>
                    <div class="flex-1">
                        <input class="profile-input" placeholder="请输入您的身份证号" v-model="id_card" />
                    </div>
                </li>
                <li class="profile-li flex flex-y">
                    <div class="profile-name">
                        <span>*</span>姓名
                    </div>
                    <div class="flex-1">
                        <input class="profile-input" placeholder="请输入您的姓名" v-model="name" />
                    </div>
                </li>
            </ul>
            <button class="check" @click="submitFun">保存并检测</button>
        </div>
        <div v-else>
            <div v-if="man" class="dooter">
                 您还不是医生
            </div>
        </div>
        <div class="loading flex flex-x flex-y" v-if="!man">
            <div class="loading-box">
                <div class="loading-icon">
                    <van-loading />
                    <div class="loading-ui">正在获取用户信息</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations , mapActions } from 'vuex';
import vueHeader from '@/components/header' 
export default {
  name: 'addUser',
  components:{
    vueHeader
  },
  created:function(){
      if(this.$route.params.id){
          this.accountData();
      }
  },
  methods:{
      submitFun(){
        this.addData(this);
      },
      back(){
          window.history.back(-1);
      },
      ...mapMutations('addUser',['change']),
      ...mapActions('addUser',['addData','userData']),
      ...mapActions('prescribeQuestion',['accountData'])
  },
  computed:{
    iphone:{
        get() { return this.$store.state.addUser.iphone },
        set(val){this.change({
            key:'iphone',
            value:val
        });}
    },
    id_card:{
        get() { return this.$store.state.addUser.id_card },
        set(val){this.change({
            key:'id_card',
            value:val
        });}
    },
    name:{
        get() { return this.$store.state.addUser.name },
        set(val){this.change({
            key:'name',
            value:val
        });}
    },
    ...mapState('prescribeQuestion',['doctor','man']),
  }
}

</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import 'addUser';
</style>